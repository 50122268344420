






























































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PostJob } from "@/interfaces/global_valley/job_parser/post_job/post_job";
import {
  GET_JOBS_LISTING,
  GET_POST_JOB_LOADING,
  POST_JOB
} from "@/store/modules/job_parser/constants";
import router from "@/router";
import {
  GET_RESUME_LISTING,
  GET_RESUME_LISTING_LOADING,
  GET_UPLOADED_RESUMES_ID,
  GET_UPLOADED_RESUMES_STATUS
} from "@/store/modules/resume_parser/constants";
import { SET_NOTIFICATION } from "@/store";
import { ResumeListingActionPayload } from "@/store/modules/resume_parser/interfaces";

export default Vue.extend({
  name: "PostJob",
  data(): PostJob {
    return {
      job_file: null,
      selected_file: null,
      drop_file_image: require("@/assets/illustrations/file.svg"),
      error: false,
      error_message: ""
    };
  },
  computed: {
    ...mapGetters("job_parser", {
      post_job_loading: GET_POST_JOB_LOADING
    }),
    ...mapGetters("resume_parser", {
      get_resumes_Ids: GET_UPLOADED_RESUMES_ID,
      get_uploaded_resumes: GET_RESUME_LISTING,
      resume_listing_loading: GET_RESUME_LISTING_LOADING
    })
  },
  watch: {
    async resume_listing_loading(n) {
      // If no resumes uploaded => navigate to post resume page
      if (!n) {
        if (this.get_uploaded_resumes.resumes.length <= 0) {
          this.set_notification("Please upload some resumes first");
          await router.push({
            name: "global-valley-post-resume"
          });
        }
        // if ids exist in session storage => checking ids status
        else if (this.get_resumes_Ids.length > 0) {
          if (!(await this.get_resumes_status(this.get_resumes_Ids))) {
            await router.push({
              name: "global-valley-resume-listing"
            });
          }
        }
      }
    }
  },
  async created() {
    const obj: ResumeListingActionPayload = {
      page: 0,
      limit: 39
    };
    await this.fetch_uploaded_resumes(obj);
  },
  methods: {
    ...mapActions("resume_parser", {
      get_resumes_status: GET_UPLOADED_RESUMES_STATUS,
      fetch_uploaded_resumes: GET_RESUME_LISTING
    }),
    ...mapActions("job_parser", {
      post_job: POST_JOB,
      fetch_posted_jobs: GET_JOBS_LISTING
    }),
    ...mapMutations({
      set_notification: SET_NOTIFICATION
    }),
    /**
     * Process user selected files, through drag & drop
     * @param value: DragEvent
     */
    process_dropped_files(value: DragEvent) {
      if (value && value.dataTransfer) {
        const files: File[] = Array.from<File>(value.dataTransfer.files);
        this.validate_file(files[0]);
      }
    },
    /**
     * Process user selected files, through file uploader
     * @param file: File => Selected file
     */
    process_selected_files(file: File) {
      this.validate_file(file);
    },

    /**
     * Validate selected file type: .docs, .pdf & .txt are valid types
     * @param file: File => selected file
     * @return boolean
     */
    validate_file(file: File): boolean {
      if (file.size > 3e7) {
        this.error = true;
        this.error_message = "File size should be less than 30 MB!";
        this.job_file = null;
        return false;
      } else if (
        file.type === "text/plain" ||
        file.type === "application/pdf" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/doc" ||
        file.type === "application/ms-doc" ||
        file.type === "application/msword"
      ) {
        this.error_message = "";
        this.error = false;
        this.selected_file = file;
        return true;
      } else {
        this.error = true;
        this.error_message =
          "Unsupported file type. .pdf, .docx, .txt files are allowed";
        this.job_file = null;
        return false;
      }
    },
    /**
     * Convert file size from bytes to MB
     * @param size: number file size
     * @return string file size in MB
     */
    get_file_size(size: number): string {
      return (size / (1024 * 1024)).toFixed(2);
    },
    /**
     * Reset variables
     */
    reset_resume() {
      this.job_file = null;
      this.selected_file = null;
      this.error_message = "";
      this.error = false;
    },
    /**
     * Submit form
     */
    async submit_form() {
      const obj_ = {
        page: 0,
        limit: 10
      };
      await this.fetch_posted_jobs(obj_);
      const obj = new FormData();
      obj.append("job", this.job_file as never);
      await this.post_job(obj);
    }
  }
});
